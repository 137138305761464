import React, { useEffect, useState } from 'react';
import {Button, TextField, Dialog, DialogTitle,
        DialogActions, DialogContent, DialogContentText, IconButton, Tooltip, Divider, Select, InputLabel, MenuItem, Typography} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useEditVisitMutation } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import GoalInput from './GoalInput';
import EditIcon from '@mui/icons-material/Edit';
// import useDeepCompareEffect from 'use-deep-compare-effect';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenState } from '../../redux/slices/visitSlice';
import SignVisit from './SignVisit';
import { useAuth } from '../../utilities/useAuth';
import useSetVisitGoals from '../../utilities/useSetVisitGoals';

const statusOptions = [
  { label: 'Scheduled', value: 'Scheduled'},
  { label: 'On-Site', value: 'On-Site'},
  { label: 'Completed', value: 'Completed'},
  { label: 'Canceled', value: 'Canceled'},
]

const DirectEditVisit = ({ visitId, visit, clientName }) => { 

  console.log("visit: ", visit)
  const {id} = useAuth();
  const isUser = id;

  const dispatch = useDispatch()
  const closeFromSign = useSelector(state => state.visit.openModal)

  const [open, setOpen] = useState(false);
  const [clientId, setClientId] = useState();
  const [newStatus, setNewStatus] = useState('');

  const [editVisit] = useEditVisitMutation();

  useEffect(() => {
    if(open) {
        // const getId = visit.client;
        setClientId(visit.client)
        dispatch(setOpenState(true))
        setValue("client", visit?.client)
      }
  }, [open])
  // console.log("clientId: ", clientId, "visit.client: ", visit.client)

  useEffect(() => {
    if(!closeFromSign){
      handleClose()
    }
  }, [closeFromSign])
  

  const hasGoals = visit?.goals;
  const searchStart = visit?.visitStart;
  const searchEnd = visit?.visitEnd;


  const goals = useSetVisitGoals({clientId, hasGoals, searchStart, searchEnd})
  


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const { register, handleSubmit, control, setValue, watch, getValues,  formState: { errors, dirtyFields } } = useForm({
    defaultValues: {
    client: visit?.client,
    visitStart: visit?.visitStart,
    visitEnd: visit?.visitEnd,
    location: visit?.location,
    goals: visit?.goals,
    status: visit?.status,
    cancelNote: visit?.cancelNote,
    clientSig: visit?.clientSig
    },
    shouldUnregister: true
  });

  
  
 
 const onSubmit= async (data) => {
  
  // console.log("visit data: ", data)
  const _data = data.goals.filter(goal => goal.marked != false)
  data.goals = _data
  console.log("post data: ", data)
                 
  try {
    // const payload =  
    await editVisit({ visitId, data, isUser })
    // .unwrap();
    //   console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save visit: ', err)
    }

    toast.success("Visit Updated", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
 }

 const onError = (errors) => {
  toast.error("Error Updating Visit", {
    position: toast.POSITION.TOP_CENTER
  });
   console.log('error in visit form: ', errors);
 }

 const generateOptions = () => {
  return statusOptions.map((option) => {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  });
};

 const watchStatus = watch('status')

 useEffect(() => {
  if (open  && (visit.status === 'Completed' && !visit.clientSig)){
    setNewStatus('Completed')
  }
  if (dirtyFields.status) {
    if (watchStatus === 'Completed') {
      setNewStatus('Completed')
    } else if (watchStatus === 'Canceled') {
      setNewStatus('Canceled')
    }
  }
}, [dirtyFields, watchStatus, open])


useEffect(() => {
  if(goals) {
    goals.forEach((g, idx) => {
      if (g.marked) {
        setValue(`goals.${idx}.title`, g.title)
      }
    })
  }
}, [goals])


  return (
    <div>
      <IconButton color='secondary' variant='contained' onClick={handleClickOpen}>
        <Tooltip title="Edit Visit">
          <EditIcon />
        </Tooltip>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Visit</DialogTitle>
        <DialogContent>

        <DialogContentText>
            Visit for {clientName}
        </DialogContentText>
    
        
          <form id="editVisit" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
        
     <br></br>
     <section>
      <Controller
        control={control}
        name="visitStart"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
         
          label="Visit Start"
          error={!!errors.visitStart}
          value={field.value}
          onChange={(e)=>field.onChange(e)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.visitStart && "Select Time In"}
      </section>
      <br></br>
      <section>
      <Controller
        control={control}
        name="visitEnd"
        
  
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
             
              label="Visit End"
              error={!!errors.visitEnd}
              value={field.value}
              onChange={(e)=>field.onChange(e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          )}
          />
      <br></br>
      {errors.visitEnd && "Select Time Out"}
      </section>
      <br></br>
      <section>
        <TextField
          {...register('location', { required: true})}
          error={!!errors.location}
          label="Location"
          name='location'
          id='location'
          required
        />
      <br></br>
        {errors.location && "List a location for the visit"}
      </section>
      <br />
      <section>
        <InputLabel id="status-label">Status</InputLabel>
        <Controller
          name="status"
          control={control}
          labelId="status-label"
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={field.onChange}
              label="Status"
            >
              {generateOptions()}
            </Select>
          )}
        />
        <br />
        {newStatus === 'Canceled' && 
          <TextField
            name='cancelNote'
            {...register('cancelNote')}
            label='Reason for Cancellation'
          />
        }
      </section>
      {goals?.length >= 1 ? ( goals.map((goal, index) => (
          <GoalInput 
            key={index}
            goal={goal} 
            index={index} 
            control={control}
            errors={errors}
            setValue={setValue}
          />
      ))
      ):(
        <Typography>No service plan with goals for this date. Goals must be set in the plan to reflect here.</Typography>
      )}
     
     <br></br>
     {visit.clientSig && visit.status === 'Completed' ? <img src={visit?.clientSig} style={{wdith: '200px', height: '200px'}} /> : "" }
        </form>
        <Divider sx={{m: 1, p: 1}} />
        </DialogContent>
        <DialogActions>
          <Button color='warning' onClick={handleClose}>Cancel</Button>
          <Button color="primary" variant="contained" form="editVisit" type="submit">
            Save
          </Button>
          {newStatus === 'Completed' && visit.clientSig == null ? (
            <>
            <br />
            <SignVisit control={control} visit={getValues()} visitId={visitId} isUser={isUser} name={clientName} />
            </> ) : ('')
          }
        </DialogActions>
        </Dialog>
        
        </div>
    )}

DirectEditVisit.propTypes = {
  visitId: PropTypes.any, 
  visit: PropTypes.any,
  clientName: PropTypes.any
}

export default DirectEditVisit;
